import React from 'react'
import PropTypes from 'prop-types'

import { SITE_URL } from '../constants'

import Helmet from 'react-helmet'

import faviconPng from '../assets/favicon.png'
import faviconSvg from '../assets/favicon.svg'

function SEO({ description, lang, meta, siteTitle, title, url, shareImage }) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${siteTitle} - %s`}
      defaultTitle={siteTitle}
      link={[
        { rel: 'icon', type: 'image/svg+xml', href: faviconSvg },
        { rel: 'alternate icon', type: 'image/png', href: faviconPng },
      ]}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: shareImage,
        },
        {
          property: 'og:url',
          content: url || SITE_URL,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: meta?.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: 'twitter:image',
          content: shareImage,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: '',
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
